import React from "react"
import { css } from "@emotion/react"
import { Link } from "gatsby"

const BlogThumbnail = ({ blog_url, title, body, children}) => (
	<Link to={blog_url} css={css`color: black;`}>
		<div className="card">
			{children}
			<div className="card-body">
				<h5 className="card-title">					
					{title}					
				</h5>
				<p className="card-text">{body}</p>
			</div>
		</div>
	</Link>
)

export default BlogThumbnail