import React from "react"
import Layout from "../../components/layout/layout"
import { css } from "@emotion/react"
import Seo from "../../components/seo"
import BlogThumbnail from '../../components/blog-thumbnail/blog-thumbnail'
import { StaticImage } from "gatsby-plugin-image"

const CVIndexPage = () => {
	return (
    <Layout>
		<Seo title="Computer Vision" 
			keywords={['Image Segmentation', 'Image Processing','Ganiyu AJ Ibraheem']}/>
        <div className="hidden md:block " css={css`margin-top: 2rem;`}>
            <div className="grid md:grid-cols-2 justify-center">
                <div className="md:max-w-md">
                    <StaticImage src="./../../images/geordanna-cordero-fields-762610-unsplash.jpg" alt=""/>
                </div>
                <div className="md:max-w-md text-left px-4 max-w-prose md:text-sm" css={css`margin-top: 3rem;`}>
                    <h4>Computer Vision</h4>
                    <p>This is an interdisciplinary field that enables computers to gain high-level understanding from images and videos</p>
                    <p>This publication includes guides and tutorials illustrating computer vision algorithms, their applications and caveats involved with their useage</p>
                    <small className="text-muted">EDITORS</small>
                    <small className="" css={css`
                        display: block;
                    `}>AJ Ibraheem</small>
                </div>
            </div>
        </div>

        <div className="grid md:grid-rows-1" css={css`background-color: rgba(236, 240, 241, 1);`}>
            <div className="grid md:grid-cols-2 md:gap-4" css={css`margin: 2rem; padding: 1rem;`}>
                <div className="md:max-w-md ">
                    <BlogThumbnail 
                        title="Image Processing"
                        blog_url="/blogs/computer-vision/image-processing/"
                        body="Extracting information from images using signal processing techniques">
                            <StaticImage 
                                src="./../../images/josh-rose-506979-unsplash.jpg" 
                                alt="Thumbnail for image processing publication" 
                                className="thumbnail-image" />
                    </BlogThumbnail>
                        
                </div>
                <div className="md:max-w-md">
                    <BlogThumbnail 
                        title="Geometric Vision"
                        blog_url="/blogs/computer-vision/geometric-vision"
                        body="Relationship between 3D world objects and their 2D image projection">
                            <StaticImage 
                                src="./../../images/dhruv-weaver-618958-unsplash.jpg" 
                                alt="Thumbnail for geometric vision publication"
                                className="thumbnail-image" />
                    </BlogThumbnail>
                </div>
            </div>
        </div>
	</Layout>)
}

export default CVIndexPage


//